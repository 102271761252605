import React from 'react';

const HeaderLogo = ( { logoColor } ) => {
    // console.log(logoColor)
    return (
        <div className="header-logo">
            <a className="logo logo-secondary transform-scale-h" title="Logo" href={ process.env.PUBLIC_URL + "/" }>
                <img src={ logoColor==="light" ? "assets/img/logo/logowhite.png" : "assets/img/logo/caritaslogonude.png"  } alt="Logo" style={{width: window.innerWidth<=999 ? "43vw": "10vw" }} />
            </a>
        </div>
    );
};

export default HeaderLogo;
