import React from 'react';





const AboutsUs = () => {
    return (
        <section id="about-us" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="row gutter-width-sm">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="title">
                            <h2 className="text-uppercase">About Me </h2>
                            <h3>Caritas Associates</h3>
                            <p>We provide cross-border strategic advisory services to industry executives and investors in life sciences, biotech, pharmaceuticals, medical devices, health services, technology, consumer sectors.  Services include brand and omnichannel strategy, organizational and operational optimization, market intelligence, industry outlook, due diligence, comparable and scenario analyses, technical and commercial risk assessment,  valuation, strategic alliance, IPO strategy.   In addition to biotechnology, we also focus on predictive analytics, digital health, AI, and ML.</p>
                              <h4>We are based in New York, Beijing, Shanghai.</h4>  
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
            <div className="row gutter-width-sm justify-content-end">
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                    <div className="description">
               
            </div>
                </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>    
                    <div className="row gutter-width-sm">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="description">
                          
                        </div>
                    </div>
                </div>

                   

                 
                
                    
                 
                    
             
             


            </div>
        </section>
    );
};

export default AboutsUs;
